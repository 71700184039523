import 'animate.css';
import Page from './Page'
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import request from 'superagent';

const baseUrl = 'https://dennisalison.com/api'
// const baseUrl = 'http://localhost:3001'

function App(props) {
  let { id } = useParams();
  let [recipient, setRecipient] = useState();
  let [fetching, setFetching] = useState(false)

  useEffect(() => {
    if (id && !recipient && !fetching) {
      const fetch = async () => {
        setFetching(true)
        const res = await request.get(baseUrl + '/' + id);
        setRecipient(res.body)
        setFetching(false)
      }
      fetch();
    }
  }, [id, recipient, fetching])

  const confirm = (type, confirmation) => async() => {
    const data = {
      type,
      confirmation,
    }
    try {
      const res = await request.post(baseUrl + '/' + id + '/confirm').send(data);
      if (res.status == 200 && res.body.success) {
        const temp = {...recipient}
        if (type == 'holyMatrimony') {
          temp.holyMatrimonyConfirmation = confirmation;
        } else if (type == 'reception') {
          temp.receptionConfirmation = confirmation;
        }
        setRecipient(temp)
      }
    } catch (err) {
      // ignore
    }
  }
  const showReception = (recipient && recipient.reception) || props.isFamily || props.isBali
  const showHolyMatrimony = (recipient && recipient.holyMatrimony) || !showReception || props.isFamily || props.isBali
  const showTitle = showReception && showHolyMatrimony
  const showRSVP = recipient && (recipient.holyMatrimonyConfirmation == null || (recipient.reception && recipient.receptionConfirmation == null))

  return (
    <>
      <Page>
        <div className="position-relative flex-fill d-flex flex-column px-4 px-md-5 text-center">
          <div className="flex-fill d-flex flex-column justify-content-center align-items-center" style={{paddingTop: '10vh'}}>
            <h2 className="invitation-text initial-text display-5 show-print">INVITATION</h2>
            <div className="initial-img animate__animated animate__fadeInDown"></div>
            <h4 className="initial-text animate__animated animate__fadeInDown animate__delay-1s">{props.isBali ? '8th February 2023' : '28th January 2023'}</h4>
          </div>
          {recipient && <p className="position-absolute bottom-0 start-50 mb-5" style={{transform: 'translateX(-50%)'}}>To:<br />{recipient.recipient}</p>}
        </div>
      </Page>
      <div className="position-absolute start-50 bottom-0 d-flex hide-print" style={{transform: 'translateX(-50%)'}}>
        <span className="material-icons display-2 animate__animated animate__fadeInDown animate__infinite text-muted" style={{lineHeight: '.5em', transform: 'translateX(-50%)'}}>keyboard_arrow_down</span>
      </div>
      <Page>
        <div className={"title-cont px-4 px-md-5 text-end" + (props.isBali ? ' title-cont-bali' : '')}>
          <p className="m-0">We cordially invite you<br />to the wedding ceremony of:</p>
          <div className="position-relative d-flex flex-column">
            <h1 className='title-font initial-text m-0 display-1 animate__animated animate__fadeInRight'>Dennis</h1>
            <span className="position-absolute bottom-0 end-0 title-caption">First son of Mr. Kiki Natan and Mrs. Joyce Nie Ing Hong</span>
          </div>
          <div className="position-relative d-flex flex-column">
            <h1 className='title-font initial-text m-0 display-1 animate__animated animate__fadeInRight animate__delay-1s'><span className="title-conj">and </span>Alison</h1>
            <span className="position-absolute bottom-0 end-0 title-caption">First daughter of Mrs. Anita Lim</span>
          </div>
        </div>
        {props.isBali && <div className='text-end mt-3 px-4 px-md-5 animate__animated animate__fadeInRight animate__delay-2s'>
          <p className="m-0 subtitle-font location-title">8th February 2023 - Bali Celebration</p>
          <p><em>
            Dinner to commence at 6 PM<br />
            <a href="https://goo.gl/maps/g41XDeTuSWJvpVFW9" target="_blank" rel="noreferrer" className="d-flex justify-content-end">
              <span className="material-icons initial-text">place</span>
              <span>Hongkong Garden Restaurant, Jl. Bypass Ngurah Rai Gg. Kertapura VIII No.8, Denpasar, Bali</span>
            </a>
            </em></p>
        </div>}
        {showHolyMatrimony && <div className={`text-end px-4 px-md-5 animate__animated animate__fadeInRight ${props.isBali ? 'animate__delay-3s' : 'animate__delay-2s'} ${(recipient && recipient.reception) || props.isBali ? 'mt-3' : 'mt-5'}`}>
          {props.isBali && <p className="m-0 subtitle-font location-title" style={{lineHeight: '1em'}}>28th January 2023 - Surabaya</p>}
          {showTitle && <p className="m-0 subtitle-font location-title">Holy Matrimony</p>}
          <p className={props.isBali ? 'm-0' : ''}><em>
            Ceremony to commence at 2 PM<br />
            <a href="https://goo.gl/maps/QGiP9Mcc8y1jgi6P9" target="_blank" rel="noreferrer" className="d-flex justify-content-end">
              <span className="material-icons initial-text">place</span>
              <span>Sanctuary Room, Vision Building, Marvell City{props.isBali ? '' : ', Surabaya'}</span>
            </a>
            </em></p>
        </div>}
        {showReception && <div className={`text-end px-4 px-md-5 animate__animated animate__fadeInRight ${props.isBali ? 'mt-0 animate__delay-4s' : 'mt-3 animate__delay-3s'}`}>
          {showTitle && <p className="m-0 subtitle-font location-title">Celebration</p>}
          <p><em>
            Reception to commence at 6 PM<br />
            <a href="https://goo.gl/maps/ixTbSK8ZrqzNXXcL8" target="_blank" rel="noreferrer" className="d-flex justify-content-end">
              <span className="material-icons initial-text">place</span>
              <span>Casa Milieu, Citraland Waterfront WP 1 No.16{props.isBali ? '' : ', Surabaya'}</span>
            </a>
            </em></p>
        </div>}
        {props.isBali && <div className="text-center px-4 px-md-5 mt-3">
          <p>Valid for <strong className="fw-bold">2 persons</strong></p>
        </div>}
      </Page>
      {recipient && <Page>
        <div className="title-cont"></div>
        <div className="px-4 px-md-5 text-center">
          <div>
            <h2 className="m-0 subtitle-font initial-text subtitle-text">RSVP</h2>
            <p>Due to limited seating arrangements, invitations are limited to <strong className="fw-bold">{recipient.pax} persons</strong></p>
          </div>
          {showHolyMatrimony &&
            <div className="pt-3 pt-md-5">
              {showTitle && <h3 className="subtitle-font">Holy Matrimony</h3>}
              {recipient.holyMatrimonyConfirmation == null && <div className='d-flex flex-column flex-md-row gap-2 justify-content-md-around'>
                <button className="btn btn-primary" disabled={fetching} onClick={confirm('holyMatrimony', true)}>Accept with pleasures</button>
                <button className="btn btn-light" disabled={fetching} onClick={confirm('holyMatrimony', false)}>Decline with regrets</button>
              </div>}
              {recipient.holyMatrimonyConfirmation != null &&
                <p>{recipient.holyMatrimonyConfirmation ? 'You already accepted' : 'You already declined'}</p>
              }
            </div>
          }

          {showReception &&
            <div className="pt-3 pt-md-5">
              {showTitle && <h3 className="subtitle-font">Celebration</h3>}
              {recipient.receptionConfirmation == null && <div className='d-flex flex-column flex-md-row gap-2 justify-content-md-around'>
                <button className="btn btn-primary" disabled={fetching} onClick={confirm('reception', true)}>Accept with pleasures</button>
                <button className="btn btn-light" disabled={fetching} onClick={confirm('reception', false)}>Decline with regrets</button>
              </div>}
              {recipient.receptionConfirmation != null &&
                <p>{recipient.receptionConfirmation ? 'You already accepted' : 'You already declined'}</p>
              }
            </div>
          }
          {showRSVP && <p className="pt-3 pt-md-5">Kindly confirm before <strong className="fw-bold">20th January 2023</strong></p>}
          { !showRSVP && <p className="pt-3 pt-md-5 fw-bold">Thank you for your confirmation</p>}
        </div>
      </Page>}
    </>
  );
}

export default App;
