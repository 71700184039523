import 'animate.css';
import './App.scss'

function Page(props) {
  return (
    <div className="viewPort d-flex">
      <div className="container d-flex align-items-center justify-content-center">
        <div className="row paper my-3 d-flex flex-column">
          <div className="innerPaper">
          </div>
          <div className="flower-cont"><img src="/flower.png" alt="" /></div>
          <div className="d-flex flex-column flex-fill" style={{zIndex: '1'}}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
